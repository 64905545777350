.tree {
    width: 100%;
    height: 100%;

    cursor: url("../../../assets/icons/cursor.svg"), auto;
}

canvas {
    cursor: url("../../../assets/icons/cursor_hover.svg"), auto;
}

.reload {
    position: absolute;
    right: 5px;
    bottom: 5px;

    border: none;
    background-color: white;
}