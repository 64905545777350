#homepage {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    background-color: var(--amplify-colors-black);

    cursor: url("~/src/assets/icons/cursor_white.svg"), auto;

    #signout {
        cursor: url("~/src/assets/icons/cursor_hover_white.svg"), auto;
        position: absolute;
        top: 0;
        right: 0;
        margin: 25px;
        padding: 15px;
        background-color: var(--amplify-colors-black);
        border: none;
        color: var(--amplify-colors-white);
        font-family: "Handwriting";
        font-weight: 600;
    }

    #signout:hover {
        border: 1px solid white;
    }
}

@media only screen and (min-width: 768px) {
    #homepage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}