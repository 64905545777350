.content-block {
    width: 70%;
    margin-bottom: 5vh;
}

.content-block.header {
    text-align: center;
    font-size: x-large;
    font-family: Handwriting;
}

.content-block.paragraph {
    font-size: x-large;
    font-family: Montserrat;
}

.content-block.img {
    text-align: center;
    font-size: medium;
}