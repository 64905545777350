.timelinepage {
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__sections_menu_wrapper {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        bottom: 0;
        height: 20%;
        width: 100%;
        overflow: hidden;
    }

    &__chapters_menu_wrapper {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        top: 0;
        height: 80%;
        width: 100%;
        overflow: hidden;
    }

    &__sections_menu_wrapper_temp {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: 50%;
        width: 50%;
    }
}

.back-button {
    cursor: url('~/src/assets/icons/cursor_hover_white.svg'), auto;
}