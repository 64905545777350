#landingpage {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__content {
        height: 80%;
        width: 80%;

        box-shadow: 0px 0px 100px 2px black;

        display: flex;
        flex-direction: column;

        #hero {
            width: 100%;
            height: 40%;
            background: linear-gradient(10deg, rgba(255, 255, 255, 1) 0%, rgba(163, 240, 255, 1) 75%, rgba(0, 212, 255, 1) 100%);
        }

        #auth {
            width: 100%;
            height: 60%;

            display: flex;
            flex-direction: column;
            justify-content: center;

            .amplify-button--primary {
                background-color: rgba(0, 212, 255, 1);
            }

            .amplify-button--link {
                color: grey;
            }

            .amplify-tabs-item {
                border: none;
                color: gray;
            }

            [data-amplify-container] {
                width: 80%;
                border-color: white;
            }

            [data-amplify-router] {
                width: 100%;
                border-color: white;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    #landingpage {
        &__content {
            flex-direction: row;

            #hero {
                height: 100%;
                width: 70%;
            }

            #auth {
                height: 100%;
                width: 30%;

                [data-amplify-container] {
                    width: 80%;
                    border-color: white;
                }

                [data-amplify-router] {
                    width: 100%;
                    border-color: white;
                }
            }
        }
    }
}