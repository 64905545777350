#chapters_menu {
    height: 100%;
    width: 100%;
    background-color: black;
    cursor: url("../../../assets/icons/cursor_white.svg"), auto;

    &__wrapper {
        height: 100%;
        position: absolute;
        width: 100%;
        overflow-y: scroll;
    }

    &__menu {
        cursor: url("../../../assets/icons/cursor_white.svg"), auto;
        color: white;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding-top: 100px;
        height: fit-content;
        width: 100%;

        a {
            text-decoration: none;
            cursor: url("../../../assets/icons/cursor_hover_white.svg"), auto;
        }
    }

    &__chapter {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;

        svg {
            opacity: 0;

            transition: opacity 1s;
        }

        &__time {
            color: white;
            font-size: 30px;
        }
    }

    &__chapter:hover svg {
        opacity: 1;
    }

    button {
        width: fit-content;
        cursor: url("../../../assets/icons/cursor_hover_white.svg"), auto;
        border: none;
        background: none;
        font-size: 20px;
        font-family: FairDisplay;
        color: white;
    }
}

@media only screen and (min-width: 1000px) {
    #chapters_menu {
        button {
            font-size: 60px;
        }
    }
}