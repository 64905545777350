#sections_menu {
    width: fit-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: url("../../../assets/icons/cursor.svg"), auto;
    background-color: white;

    button {
        height: fit-content;
        padding-left: 80px;
        padding-right: 80px;
        cursor: url("../../../assets/icons/cursor_hover.svg"), auto;
        border: none;
        background: none;
        font-size: 80px;
    }

    &__container {
        font-family: 'Handwriting';
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}